import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import Trailer from '../../video/triphunt.mp4'
import { Link } from 'gatsby'

const TriphuntPage = () => (
  <Layout>
    <SEO
      title="Triphunt"
      description="Read the case study of Triphunt, the first mobile outdoor platform for personalised outdoor experiences."
    />
    <section>
      <hgroup>
        <h6>2018</h6>
        <h2>Triphunt</h2>
      </hgroup>
      <div className="project-header-image">
        <Image filename="phones-triphunt" />
        <div className="text-center mt-40">
          <a href="https://triphunt.de" className="btn">
            Visit Triphunt Website
          </a>
        </div>
      </div>
      <div className="section-columns">
        <div>
          <h3>Overview</h3>
          <p>
            Triphunt is the first mobile outdoor platform, which connects and
            analysis different data systems in destinations and creates
            personalised outdoor experiences.
          </p>
          <p>
            The experience of nature is the greatest motivation for outdoor
            athletes and is often disturbed by overcrowded spots. The research
            and planning of new outdoor trips takes a long time, as the
            information is spread on different platforms, unreliable and not
            detailed enough. Moreover the user doesn’t get individual
            information on site. Collective planning with friends or family is
            not possible in current market solutions.
          </p>
        </div>
        <div className="pt-48">
          <p>
            The platform creates personalised trip suggestions with detailed
            information for the next outdoor adventure. The suggestions are
            based on location, weather, equipment, reviews, friends, interests
            and the experience level. With the social community, users are able
            to plan collectively with friends and family, connect with other
            athletes and get inspired for the next adventure.
          </p>
          <p>
            Since I was working on the project alone, I was completely in charge
            of the whole research, market analysis, user interviews, concept,
            branding, UX/UI design, development, financial plan and business
            plan. I’ve created the app design and a visual click dummy and also
            developed a first technical proof of concept in React and React
            Native to see if the idea could work with existing API’s. Besides
            the product development I also build a first landing page which
            collected mail signups and produced a short product trailer.
          </p>
        </div>
      </div>
      <video controls width="100%" src={Trailer} type="video/mp4" />

      <div className="section-columns">
        <div>
          <h3>Challenge</h3>
          <p>
            There were many challenges to tackle in the market. The outdoor
            market plays an important role in tourism. There are already lot’s
            of different apps in the market, which all try to solve an issue.
            Some are focussed on one type of sports, some try to offer tours.
            None of the major apps offer personalised experiences. They are all
            just small pieces of a trip planning activity, but don’t offer a
            fully comprehensive and easy planning. During the guest journey, the
            planning step plays an important role and users complained about the
            amount of spended time for this step. Planning should be
            <b> fun, easy and fast.</b>
          </p>
          <p>
            One of the major issues in outdoor tourism is overtourism.
            Overtourism destroys the experience of nature, as there are too many
            people at the same location at the same time. Therefore it was
            important to include a solution, which automatically decreases the
            problem of overtourism.
          </p>
          <p>
            Also within destinations there are many isolated software solutions,
            which save guest information. Combining the data is is a hidden gem
            for destination managers.
          </p>
        </div>
        <div>
          <h3>Approach</h3>
          <p>
            To understand the outdoor market I did a separate research project
            before developing the concept of Triphunt. In the research I
            analysed the change of the digital guest journey, studied the pains
            of brands and destinations and also how they are tackling
            digitalisation. Moreover I did a huge user survey, which collected
            lot’s of feedback and information from over 450 outdoor sports
            enthusiasts. The goal of the research was to find new potentials for
            innovation in the digital outdoor tourism and wether a combination
            of brands and destinations would help to increase the guest
            experience. The findings of the research shaped the ground for the
            concept of Triphunt.
          </p>
          <p>
            It was also crucial to analyse the market competitors and the
            general outdoor market sales figures, to create a working business
            model.
          </p>
          <p>
            Moreover I was searching for existing API’s in destination software
            to accumulate guest data and create personalised tour suggestions.
            Open API’s play an important role for the product to work.
          </p>
        </div>
      </div>
      <Image filename="userjourney" />
      <div className="section-columns">
        <div>
          <h3>Implementation</h3>
          <p>
            After the intial research I started to work on the concept, the
            ux/ui design and also wrote the businessplan. Since I was working
            alone, I needed quick feedback loops, which I setup with some of the
            survey attendees. This helped to iterate on the visual design and
            flows to build the best possible solution. Furthermore it was
            important to test the idea and concept with real data. Therefore
            I’ve build a technical proof of concept with React and connected the
            Outdooractive API for tours, the weather API for weather
            information, MEWS API for guest information and the Shopify API for
            gear suggestions.
          </p>
          <p>
            The proof of concept was successful, since it proved that it was
            possible to use route and weather information to suggest fitting
            outdoor gear for different users. I was able to combine destination
            and outdoor brand information to a new planning experience.
          </p>
        </div>
      </div>
      <div className="section-columns col-4">
        <div>
          <Image filename="triphunt-phone-1" width="100%" />
          <p>
            Users can choose their experience level, filters the suggested tours
            based on the level.
          </p>
        </div>
        <div>
          <Image filename="triphunt-phone-2" width="100%" />
          <p>
            Selecting a destination also works as filter for the final trip
            suggestions.
          </p>
        </div>
        <div>
          <Image filename="triphunt-phone-3" width="100%" />
          <p>
            Choosing a trip date helps to solve the overtourism issue. Depending
            on the selected dates, users will only see trips, which are not
            planned by many others at the same time.
          </p>
        </div>
        <div>
          <Image filename="triphunt-phone-4" width="100%" />
          <p>
            Inviting friends opens a group for the planning. Groups are able to
            vote for trips, chat and see the packing list of outdoor gear from
            each group member. Past trips of friends also add another filter to
            the suggested tours, so everyone can experience something new.
          </p>
        </div>
        <div>
          <Image filename="triphunt-phone-5" width="100%" />
          <p>
            List of suggested tours depending on the selections before. Group
            members can leave votes for favorite tours. You can also see which
            friends already did this tour to ask them about any tips or
            experiences.
          </p>
        </div>
        <div>
          <Image filename="triphunt-phone-6" imgStyle="width: 100%;" />
          <p>Packing list of gear for the tour from each group member.</p>
        </div>
        <div>
          <Image filename="triphunt-phone-7" width="100%" />
          <p>
            Single view of a tour, which contains pictures, different map views,
            weather maps and information, suggested gear and reviews.
          </p>
        </div>
        <div>
          <Image filename="triphunt-phone-8" width="100%" />
          <p>
            Single view of gear, which included basic information, friends who
            own it and also the possibility to buy the item from selected online
            stores.
          </p>
        </div>
      </div>
    </section>
    <div className="cta-section">
      <section>
        <h2>Do you have a product vision?</h2>
        <p>
          I've helped more than <strong>60+ products</strong> to realise their
          vision in a market ready product. <br /> I would be happy to help you,
          developing your digital product from scratch and get it live.
        </p>
        <a href="https://calendly.com/dannygiebe/connect" className="btn">
          Get in touch
        </a>
      </section>
    </div>
    <section>
      <div className="similar">
        <div />
        <div className="text-center">
          <Link to="/">Go to homepage</Link>
        </div>
        <div className="text-right">
          <Link to="/project/mountlytics/">Read MountLytics study</Link>
        </div>
      </div>
    </section>
  </Layout>
)
export default TriphuntPage
